import React, { Component } from 'react';
import Header from "./../../components/sections/Header";
import NotFound from '../../images/notfound.jpg'

class NoMatch extends Component {
    render() {
        return (
            <div>
                <Header/>
                <div className="heightDefult">
                  <img style={{width: '100%'}} src={NotFound} alt="NOTFOUND" />
                </div>
            </div>
        );
    }
}

export default NoMatch;
