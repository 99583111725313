import React, { Component } from 'react';
import NavItem from "../NavItem";
import Logo from '../../images/logo.png'

class HeaderFa extends Component {
    render() {
        return (
            <nav className="navbar">

                <a rel="noopener noreferrer" target="_blank" href="/fa">
                   <img className="logoNavFa" src={Logo} alt="logo" />
                </a>
                <ul  className="nav navbar-nav navFaRe fontFamily">
                    <NavItem to="/galleryFa">گالری</NavItem>
                    <NavItem activeOnlyWhenExact={true} activeClassName="selected" to="/fa">خانه</NavItem>
                    <NavItem to="/fa"> Fa </NavItem>
                    <NavItem to="/"> En </NavItem>
                </ul>
            </nav>
        );
    }
}

export default HeaderFa;
