import React, {Component} from 'react';
import Header from "./../../components/sections/HeaderFa";
import gallery1 from '../../images/Capgdsgture.jpg';
import gallery2 from '../../images/IMG_1266.png';
import gallery3 from '../../images/IMG_1267.jpg';
import gallery4 from '../../images/IMG_1269-(2).jpg';
import gallery5 from '../../images/IMG_1270.jpg';
import gallery6 from '../../images/IMG_1271.jpg';
import gallery7 from '../../images/IMG_1272.jpeg';
import gallery8 from '../../images/IMG_1273.jpg';
import gallery9 from '../../images/IMG_1274.jpg';
import gallery10 from '../../images/IMG_1275.jpg';
import gallery11 from '../../images/IMG_1276.jpg';
import gallery12 from '../../images/IMG_1287.JPG';
import gallery13 from '../../images/rozeDel.jpg';

import gallery14 from '../../images/portrait.jpeg';
import gallery15 from '../../images/f123Gh.jpeg';
import gallery16 from '../../images/selfportraite.jpeg';
import gallery17 from '../../images/imagesnew.jpg';

import gallery18 from '../../images/lastImage.jpg';


import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";


class Gallery extends Component {
    constructor() {
        super();

        this.state = {
            lightboxIsOpen: false,
            image :''
        };

        this.openLightbox1 = this.openLightbox1.bind(this);
        this.closeLightbox = this.closeLightbox.bind(this);
    }

    openLightbox1() {
        this.setState({ lightboxIsOpen: true });
    }

    closeLightbox() {
        this.setState({ lightboxIsOpen: false });
    }


    render() {
        const { lightboxIsOpen } = this.state;
        return (
            <div>
                {lightboxIsOpen && (
                    <Lightbox
                        mainSrc={this.state.image}
                        onCloseRequest={() => this.setState({ lightboxIsOpen: false })}
                    />
                )}
                {/*<Lightbox*/}
                    {/*backdropClosesModal={true}*/}
                    {/*currentImage={0}*/}
                    {/*images={[{ src: `${this.state.image}` }]}*/}
                    {/*isOpen={this.state.lightboxIsOpen}*/}
                    {/*onClose={this.closeLightbox}*/}
                    {/*theme={{*/}
                        {/*footerCount: {*/}
                            {/*display: "none"*/}
                        {/*}*/}
                    {/*}}*/}
                {/*/>*/}
                <div className="background"> </div>
                <Header/>

                <h2 className="GalleryImageGF">گالری</h2>

                <div className="rowGallery">
                    <div className="column">

                        <div className="galleryPhotoFa">
                            <img onClick={(src) =>
                                this.setState({ lightboxIsOpen: true , image: gallery18 })} alt="gallery" src={gallery18} />
                            <p>پرتره سفارشی</p>
                            <p> رنگ روغن روی بوم </p>
                            <p> ۷۰*۵۰   سانتیمتر </p>
                        </div>


                        <div className="galleryPhotoFa">
                            <img onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery17 })}  alt="gallery" src={gallery17} />
                            <p>گذر نور  ۱۳۹۷</p>
                            <p>  رنگ روغن روی بوم </p>
                            <p> ۸۵*۶۰  سانتیمتر </p>
                        </div>

                        <div className="galleryPhotoFa">
                            <img onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery1 })}  alt="gallery" src={gallery1} />
                            <p> بغض ۱۳۸۵</p>
                            <p>  مداد رنگی </p>
                            <p> ۴۰*۲۸ سانتیمتر </p>
                        </div>

                        <div className="galleryPhotoFa">
                            <img onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery2 })}  alt="gallery" src={gallery2} />
                            <p> قرار ۱۳۸۹ </p>
                            <p>  رنگ روغن روی بوم   </p>
                            <p>  ۱۱۰*۷۰ سانتیمتر </p>
                        </div>

                        <div className="galleryPhotoFa">
                            <img onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery3 })}  alt="gallery" src={gallery3} />
                            <p> آخرین گل ۱۳۹۲ </p>
                            <p> رنگ روغن روی بوم </p>
                            <p> ۴۰*۶۰ سانتیمتر </p>
                        </div>


                    </div>
                    <div className="column">
                        <div className="galleryPhotoFa">
                            <img onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery13 })} alt="gallery" src={gallery13} />
                            <p>  روز دل انگیز ۱۳۹۷ </p>
                            <p>رنگ روغن روی چوب </p>
                            <p> ۲۰*۲۰ سانتیمتر </p>
                        </div>

                        <div className="galleryPhotoFa">
                            <img  onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery11 })} alt="gallery" src={gallery11} />
                            <p> نور ۱۳۹۱ </p>
                            <p>   رنگ روغن روی بوم  </p>
                            <p>  ۹۰*۷۰ سانتیمتر</p>
                        </div>

                        <div className="galleryPhotoFa">
                            <img onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery9 })} alt="gallery" src={gallery9} />
                            <p>طبیعت بیجان ۱۳۸۵ </p>
                            <p>   رنگ روغن روی بوم  </p>
                            <p>  ۳۰*۴۰ سانتیمتر</p>
                        </div>

                        <div className="galleryPhotoFa">
                            <img  onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery14 })} alt="gallery" src={gallery14} />
                            <p> پرتره دختر جوان ۱۳۹۲  </p>
                            <p>  رنگ روغن روی بوم   </p>
                            <p>   ۶۰*۸۳ سانتیمتر  </p>
                        </div>

                        <div className="galleryPhotoFa">
                            <img onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery10 })}  alt="gallery" src={gallery10} />
                            <p> رویای شب ۱۳۸۸ </p>
                            <p> رنگ روغن روی بوم  </p>
                            <p> ۷۰*۵۰ سانتیمتر  </p>
                        </div>
                        <div className="galleryPhotoFa">
                            <img onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery4 })} alt="gallery" src={gallery4} />
                            <p>رویا ۱۳۸۸ </p>
                            <p> مدادرنگی </p>
                            <p> ۳۹*۲۵ سانتیمتر  </p>
                        </div>

                        <div className="galleryPhotoFa">
                            <img onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery15 })} alt="gallery" src={gallery15} />
                            <p> طبیعت بیجان ۱۳۸۳  </p>
                            <p>مداد رنگی </p>
                            <p>  ۲۰*۲۵ سانتیمتر  </p>
                        </div>

                    </div>
                    <div className="column">
                        <div className="galleryPhotoFa">
                            <img  onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery5 })} alt="gallery" src={gallery5} />
                            <p> کوچه قدیمی ۱۳۸۲ </p>
                            <p> رنگ روغن روی بوم   </p>
                            <p> ۴۰*۶۰ سانتیمتر </p>
                        </div>

                        <div className="galleryPhotoFa">
                            <img  onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery6 })}  alt="gallery" src={gallery6} />
                            <p> پرده باران ۱۳۸۹ </p>
                            <p> رنگ روغن روی بوم    </p>
                            <p> ۸۰*۶۰ سانتیمتر  </p>
                        </div>

                        <div className="galleryPhotoFa">
                            <img onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery8 })} alt="gallery" src={gallery8} />
                            <p>طبیعت بیجان  </p>
                            <p>   رنگ روغن روی بوم  </p>
                            <p>  ۲۲*۳۰ سانتیمتر</p>
                        </div>

                        <div className="galleryPhotoFa">
                            <img onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery12 })} alt="gallery" src={gallery12} />
                            <p> سال نو  ۱۳۸۶ </p>
                            <p>   رنگ روغن روی بوم </p>
                            <p> ۹۰*۷۰ سانتیمتر </p>
                        </div>

                        <div className="galleryPhotoFa">
                            <img onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery16 })} alt="gallery" src={gallery16} />
                            <p> سلف پرتره ۱۳۸۰ </p>
                            <p>  مداد رنگی </p>
                            <p> ۵۰*۳۵ سانتیمتر </p>
                        </div>

                        <div className="galleryPhotoFa">
                            <img onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery7 })}  alt="gallery" src={gallery7} />
                            <p> ساحل ۱۳۸۷ </p>
                            <p> رنگ روغن روی بوم  </p>
                            <p>  ۷۰*۷۰ سانتیمتر  </p>
                        </div>


                    </div>
                </div>
            </div>
        );
    }
}

export default Gallery;
