import React, { Component } from 'react';
import './../../styles/App.css';
import Header from "./../../components/sections/HeaderFa";
import Keyhan from '../../images/site-profile.jpeg'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import axios from 'axios';
import validator from 'validator';

import portfolio1 from '../../images/IMG_1276.jpg'
import portfolio2 from '../../images/IMG_1275.jpg'
import portfolio3 from '../../images/IMG_1271.jpg'
import portfolio4 from '../../images/IMG_1266.png'
import portfolio6 from '../../images/IMG_1273.jpg'
import portfolio7 from '../../images/IMG_1274.jpg'
import portfolio8 from '../../images/IMG_1269-(2).jpg'


class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields : {
                name : '',
                email : '',
                title : '',
                description : ''
            },
            errors : {},
            SuccessForm: false
        }
    }

    handleChange(event) {
        let fields = this.state.fields;
        console.log(event.target)
        let target = event.target;
        fields[target.name] = target.value;
        this.setState({fields});
    }


    handleValidation(callback) {
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        if(validator.isEmpty(fields.email)) {
            formIsValid = false;
            errors["email"] = "فیلد ایمیل نمیتواند خالی بماند";
        } else if(! validator.isEmail(fields.email)) {
            formIsValid = false;
            errors["email"] = "فرمت ایمیل اشتباه است";
        }

        this.setState({ errors },() => {
            return callback(formIsValid)
        });

    }


    handleRequest() {
        const { email , name , title,  description } = this.state.fields;

        // let data = new FormData();
        // data.append('email' , email);
        // data.append('name' , name);
        // data.append('title' , title);
        // data.append('description' , description);

        axios({
            method: 'post',
            url: 'http://sheidakeyhan.com/api/contactUs.php',
            data: {
                email,
                name,
                title,
                description
            },
            headers: {'Content-Type': 'multipart/form-data' }
        })
            .then(response => {;
                if(response.status === 200){
                    this.setState({
                        SuccessForm: true
                    })
                }
            })
            .catch(error => {
                console.log(error)
            })
    }


    handleSubmit(event) {
        event.preventDefault();
        this.handleValidation((valid) => {
            if(valid) this.handleRequest()
        })
    }


    render() {
        let settings = {
            dots: true,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            pauseOnHover: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        let settings2 = {
            dots: true,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2100,
            pauseOnHover: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        const {email , name, title , description} = this.state.fields;
        const { errors } = this.state;
        return (
            <div>
                <div className="background"> </div>
                <Header/>

                <div className="container">
                    <div className="About">
                        <div className="col-md-6">
                            <img className="sheidaKeyhan" src={Keyhan} alt="SheidaKeyhan" />
                        </div>
                        <div className="col-md-6">
                            <h2 className="titlePageFa">شیدا کیهان حقیقی</h2>
                            <p className="textAboutFa">شیدا کیهان حقیقی نقاش ایرانی در سال 1361در تهران متولد شد.در سن 16سالگی هنگامیکه در هنرستان آزادگان در رشته گرافیک تحصیل میکرد ،نقاشی را در آتلیه نقاشی آرا زیر نظر استاد ایمان ملکی شروع کرد . در سال 1386فارغ التحصیل رشته گرافیک از دانشگاه علم و فرهنگ شد و همچنین در سال 1394مدرک  لیسانس را در رشته نقاشی دریافت کرد . او در نمایشگاههای زیادی شرکت داشته از جمله نمایشگاه گروهی آتلیه نقاشی کارا(کاخ سعد آباد ،1382)و نمایشگاه گروهی آتلیه نقاشی آرا(گالری برگ ،1391) در سال 1390به عنوان فینالیست، منتخب بخش فیگوراتیو در مسابقات بین المللی ARC(مرکز نوسازی هنر در آمریکا) شد.و در سال 1392 مقام دوم بخش منظره را در مسابقات بین المللی ARC(مرکز نوسازی هنر در امریکا) کسب کرد.</p>
                        </div>

                    </div>
                </div>


                <div className="container">
                    <h2 className="GalleryImageFa">گالری</h2>
                    {/*<p className="photoGallery">Photo</p>*/}

                    <Slider {...settings}>
                        <Link className="nav-link" to="/galleryFa">
                            <div className="colorTag">
                            <div className="sliderDesign">
                                <img alt="portfolio1" src={portfolio1} />
                                <h3> نور ۱۳۹۱ </h3>
                                <p>   رنگ روغن روی بوم  </p>
                                <p> ۹۰*۷۰ سانتیمتر</p>
                            </div>
                           </div>
                        </Link>
                        <Link className="nav-link" to="/galleryFa">
                            <div className="colorTag">
                            <div className="sliderDesign">
                                <img alt="portfolio1" src={portfolio2} />
                                <h3> رویای شب ۱۳۸۸ </h3>
                                <p> رنگ روغن روی بوم  </p>
                                <p>۷۰*۵۰ سانتیمتر  </p>
                            </div>
                           </div>
                        </Link>
                        <Link className="nav-link" to="/galleryFa">
                            <div className="colorTag">
                            <div className="sliderDesign">
                                <img alt="portfolio1" src={portfolio3} />
                                <h3> پرده باران ۱۳۸۹ </h3>
                                <p> رنگ روغن روی بوم    </p>
                                <p>۸۰*۶۰ سانتیمتر  </p>
                            </div>
                           </div>
                        </Link>
                        <Link className="nav-link" to="/galleryFa">
                            <div className="colorTag">
                            <div className="sliderDesign">
                                <img alt="portfolio1" src={portfolio4} />
                                <h3> قرار ۱۳۸۹ </h3>
                                <p>  رنگ روغن روی بوم   </p>
                                <p> ۱۱۰*۷۰ سانتیمتر </p>
                            </div>
                           </div>
                        </Link>

                    </Slider>

                    <Slider {...settings2}>

                        <Link className="nav-link" to="/galleryFa">
                            <div className="colorTag">
                            <div className="sliderDesign2">
                                <img alt="portfolio1" src={portfolio6} />
                            </div>
                          </div>
                        </Link>
                        <Link className="nav-link" to="/galleryFa">
                            <div className="colorTag">
                            <div className="sliderDesign2">
                                <img alt="portfolio1" src={portfolio7} />
                            </div>
                          </div>
                        </Link>
                        <Link className="nav-link" to="/galleryFa">
                            <div className="colorTag">
                            <div className="sliderDesign2">
                                <img alt="portfolio1" src={portfolio8} />
                            </div>
                          </div>
                        </Link>

                    </Slider>

                    <a href="/galleryFa" rel="noopener noreferrer" className="colorTag">
                    <button  className="btn btnPersonal">
                        صفحه گالری
                    </button>
                    </a>
                </div>

                <div className="container">
                    <h2 className="GalleryImageFa">تماس با ما</h2>
                    <div className='col-md-6'>
                        <form onSubmit={this.handleSubmit.bind(this)} className="col-lg-12" style={{ marginTop : 20}}>
                            <div className="col-md-4">
                                <div className="form-group formFaLabel">
                                    <label >نام:</label>
                                    <input
                                        className={["form-control" , errors["email"] ? 'is-invalid' : ''].join(' ')}
                                        value={name}
                                        onChange={this.handleChange.bind(this)}
                                        type="text"
                                        name="name"
                                    />
                                    <span className="invalid-feedback rtl" style={{ display : errors["email"] ? 'block' : 'none'}}>{errors["email"]}</span>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group formFaLabel">
                                    <label>ایمیل:</label>
                                    <input
                                        value={email}
                                        onChange={this.handleChange.bind(this)}
                                        type="email"
                                        name="email"
                                        className={["form-control" , errors["email"] ? 'is-invalid' : ''].join(' ')}
                                    />
                                    <span className="invalid-feedback rtl" style={{ display : errors["email"] ? 'block' : 'none'}}>{errors["email"]}</span>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group formFaLabel">
                                    <label >موضوع:</label>
                                    <input
                                        value={title}
                                        onChange={this.handleChange.bind(this)}
                                        type="text"
                                        name="title"
                                        className={["form-control" , errors["email"] ? 'is-invalid' : ''].join(' ')}
                                    />
                                    <span className="invalid-feedback rtl" style={{ display : errors["email"] ? 'block' : 'none'}}>{errors["email"]}</span>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group formFaLabel">
                                    <label className="margin-top-20">توضیحات:</label>
                                    <textarea
                                        type="text"
                                        className={["form-control" , errors["email"] ? 'is-invalid' : ''].join(' ')}
                                        value={description}
                                        name="description"
                                        onChange={this.handleChange.bind(this)}
                                    > </textarea>
                                    <span className="invalid-feedback rtl" style={{ display : errors["email"] ? 'block' : 'none'}}>{errors["email"]}</span>
                                </div>
                            </div>
                            {this.state.SuccessForm ?
                                <p className="SuccessFormّfa">فرم شما با موفقیت ارسال شد</p>
                                :
                                <p> </p>
                            }



                            <button type="submit" className="btn btn-Design">ارسال</button>
                        </form>
                    </div>



                    <div className="col-md-5">
                        <p className="contactUsFa">شما میتوانید با موارد زیر با من در تماس  باشید</p>
                        <p className="contactUsPageFa">آدرس : تهران</p>
                        <p className="contactUsPageFa">تلفن همراه : 09125330709</p>
                        <p className="contactUsPageFa"></p>
                        <p className="contactUsPageFa">ایمیل : sheida_key@yahoo.com</p>
                        <div className="socialsIconFa">
                            <a  rel="noopener noreferrer" href="mailto:sheida_key@yahoo.com" target="_blank">
                                <i className="fa fa-yahoo"> </i>
                            </a>
                            <a  rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/sheidakeyhan/">
                                <i className="fa fa-instagram"> </i>
                            </a>
                            <a  rel="noopener noreferrer" target="_blank" href="/">
                                <i className="fa fa-paper-plane"> </i>
                            </a>
                            <a  rel="noopener noreferrer" target="_blank" href="http://facebook.com/sheidakeyhanhagighi">
                                <i className="fa fa-facebook"> </i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
