import React, {Component} from 'react';
import Header from "./../../components/sections/Header";
import gallery1 from '../../images/Capgdsgture.jpg';
import gallery2 from '../../images/IMG_1266.png';
import gallery3 from '../../images/IMG_1267.jpg';
import gallery4 from '../../images/IMG_1269-(2).jpg';
import gallery5 from '../../images/IMG_1270.jpg';
import gallery6 from '../../images/IMG_1271.jpg';
import gallery7 from '../../images/IMG_1272.jpeg';
import gallery8 from '../../images/IMG_1273.jpg';
import gallery9 from '../../images/IMG_1274.jpg';
import gallery10 from '../../images/IMG_1275.jpg';
import gallery11 from '../../images/IMG_1276.jpg';
import gallery12 from '../../images/IMG_1287.JPG';
import gallery13 from '../../images/rozeDel.jpg';


import gallery14 from '../../images/portrait.jpeg';
import gallery15 from '../../images/f123Gh.jpeg';
import gallery16 from '../../images/selfportraite.jpeg';
import gallery17 from '../../images/imagesnew.jpg';

import gallery18 from '../../images/lastImage.jpg';





// import Lightbox from 'react-images-zoom';

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";



class Gallery extends Component {
    constructor() {
        super();

        this.state = {
            lightboxIsOpen: false,
            image :'',
        };

        this.openLightbox1 = this.openLightbox1.bind(this);
        this.closeLightbox = this.closeLightbox.bind(this);
    }

    openLightbox1() {
        this.setState({ lightboxIsOpen: true });
    }

    closeLightbox() {
        this.setState({ lightboxIsOpen: false });
    }

    render() {
        const { lightboxIsOpen } = this.state;
        return (
            <div>
                {lightboxIsOpen && (
                    <Lightbox
                        mainSrc={this.state.image}
                        onCloseRequest={() => this.setState({ lightboxIsOpen: false })}
                    />
                )}



                {/*<Lightbox*/}
                    {/*backdropClosesModal={true}*/}
                    {/*currentImage={0}*/}
                    {/*images={[{ src: `${this.state.image}` }]}*/}
                    {/*isOpen={this.state.lightboxIsOpen}*/}
                    {/*onClose={this.closeLightbox}*/}
                    {/*width='1024'*/}
                    {/*zoomable={true}*/}
                    {/*rotatable={true}*/}
                    {/*theme={{*/}
                        {/*footerCount: {*/}
                            {/*display: "none"*/}
                        {/*}*/}
                    {/*}}*/}
                {/*/>*/}






                <div className="background"> </div>
                <Header/>

                <h2 className="GalleryImageG">Gallery</h2>

                <div className="rowGallery">
                    <div className="column">
                        <div className="galleryPhoto">
                           <img onClick={(src) =>
                               this.setState({ lightboxIsOpen: true , image: gallery18 })} alt="gallery" src={gallery18} />
                            <p>Custom portrait </p>
                            <p> Oil On Canvas  </p>
                            <p> 50 x 70 cm </p>
                        </div>

                        <div className="galleryPhoto">
                           <img onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery17 })} alt="gallery" src={gallery17} />
                            <p>Flee Of Light. 2018 </p>
                            <p> Oil On Canvas  </p>
                            <p> 60 x 85 cm </p>
                        </div>

                        <div className="galleryPhoto">
                           <img onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery1 })} alt="gallery" src={gallery1} />
                            <p>Swallowing Her Tears. 2006 </p>
                            <p> Colored Pencil  </p>
                            <p> 40 x 28 cm </p>
                        </div>

                        <div className="galleryPhoto">
                            <img onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery2 })} alt="gallery" src={gallery2} />
                            <p> A Date. 2010 </p>
                            <p> Oil On Canvas</p>
                            <p> 110 x 70 cm </p>
                        </div>

                        <div className="galleryPhoto">
                            <img onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery3 })} alt="gallery" src={gallery3} />
                            <p> The Last Flower. 2013</p>
                            <p> Oil On Canvas</p>
                            <p>60 x 40 cm</p>
                        </div>



                    </div>
                    <div className="column">
                        <div className="galleryPhoto">
                            <img onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery13 })} alt="gallery" src={gallery13} />
                            <p> A Pleasing Day. 2018</p>
                            <p> Oil On Board </p>
                            <p>20 x 20 cm</p>
                        </div>

                        <div className="galleryPhoto">
                            <img onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery11 })} alt="gallery" src={gallery11} />
                            <p> Light. 2012</p>
                            <p>   Oil On Canvas </p>
                            <p> 90 x 70 cm</p>
                        </div>

                        <div className="galleryPhoto">
                            <img onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery9 })} alt="gallery" src={gallery9} />
                            <p> Still Life. 2008 </p>
                            <p> Oil On Canvas </p>
                            <p> 30 x 40 cm</p>
                        </div>

                        <div className="galleryPhoto">
                            <img onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery14 })} alt="gallery" src={gallery14} />
                            <p> Portrait Of Young Girl. 2013  </p>
                            <p> Oil On Canvas </p>
                            <p> 60 x 83 cm </p>
                        </div>

                        <div className="galleryPhoto">
                            <img onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery10 })} alt="gallery" src={gallery10} />
                            <p> Dream Of Night. 2010 </p>
                            <p> Oil On  Canvas</p>
                            <p>    70 x 50 cm  </p>
                        </div>
                        <div className="galleryPhoto">
                            <img onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery4 })} alt="gallery" src={gallery4} />
                            <p> Dream. 2009 </p>
                            <p>Colored Pencil </p>
                            <p> 39 x 25 cm  </p>
                        </div>

                        <div className="galleryPhoto">
                            <img onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery15 })} alt="gallery" src={gallery15} />
                            <p> Still Life. 2004</p>
                            <p> Colored Pencil </p>
                            <p> 20 x 25 cm </p>
                        </div>

                    </div>
                    <div className="column">
                        <div className="galleryPhoto">
                            <img onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery5 })} alt="gallery" src={gallery5} />
                            <p>  The Old Alley. 2003 </p>
                            <p> Oil On Canvas </p>
                            <p>60 x 40 cm </p>
                        </div>

                        <div className="galleryPhoto">
                            <img onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery6 })} alt="gallery" src={gallery6} />
                            <p>A Curtain of Rain. 2010 </p>
                            <p>  Oil On Canvas </p>
                            <p> 80 x 60 cm</p>
                        </div>

                        <div className="galleryPhoto">
                            <img onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery8 })} alt="gallery" src={gallery8} />
                            <p> Still Life. 2006</p>
                            <p> Oil On Canvas </p>
                            <p> 22 x 30 cm </p>
                        </div>

                        <div className="galleryPhoto">
                            <img onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery12 })} alt="gallery" src={gallery12} />
                            <p> New Years. 2007 </p>
                            <p> Oil On Canvas </p>
                            <p> 90 x 70 cm </p>
                        </div>
                        <div className="galleryPhoto">
                            <img onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery16 })} alt="gallery" src={gallery16} />
                            <p> Self Portrait. 2002 </p>
                            <p> Colored Pencil </p>
                            <p> 50 x 35 cm </p>
                        </div>

                        <div className="galleryPhoto">
                            <img onClick={(src) => this.setState({ lightboxIsOpen: true , image: gallery7 })} alt="gallery" src={gallery7} />
                            <p>Seaside. 2007</p>
                            <p> Oil On Canvas </p>
                            <p> 70 x 70 cm </p>
                        </div>


                    </div>
                </div>
            </div>
        );
    }
}

export default Gallery;
