import React, { Component } from 'react';
import './../../styles/App.css';
import Header from "./../../components/sections/Header";
import Keyhan from '../../images/site-profile.jpeg'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import axios from 'axios';
import validator from 'validator';


import portfolio1 from '../../images/IMG_1276.jpg'
import portfolio2 from '../../images/IMG_1275.jpg'
import portfolio3 from '../../images/IMG_1271.jpg'
import portfolio4 from '../../images/IMG_1266.png'
import portfolio6 from '../../images/IMG_1273.jpg'
import portfolio7 from '../../images/IMG_1274.jpg'
import portfolio8 from '../../images/IMG_1269-(2).jpg'


class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields : {
                name : '',
                email : '',
                title : '',
                description : ''
            },
            errors : {},
            SuccessForm: false
        }
    }

    handleChange(event) {
        let fields = this.state.fields;
        console.log(event.target)
        let target = event.target;
        fields[target.name] = target.value;
        this.setState({fields});
    }


    handleValidation(callback) {
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        if(validator.isEmpty(fields.email)) {
            formIsValid = false;
            errors["email"] = "فیلد ایمیل نمیتواند خالی بماند";
        } else if(! validator.isEmail(fields.email)) {
            formIsValid = false;
            errors["email"] = "فرمت ایمیل اشتباه است";
        }

        this.setState({ errors },() => {
            return callback(formIsValid)
        });

    }




    handleRequest() {
        const { email , name , title,  description } = this.state.fields;

        // let data = new FormData();
        // data.append('email' , email);
        // data.append('name' , name);
        // data.append('title' , title);
        // data.append('description' , description);

        axios({
            method: 'post',
            url: 'http://sheidakeyhan.com/api/contactUs.php',
            data: {
                email,
                name,
                title,
                description
            },
            headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(response => {
            // console.log(data);
            if(response.status === 200){
                this.setState({
                    SuccessForm: true
                })
            }
        })
            .catch(error => {
                console.log(error)
            })
    }


    //
    // async handleRequest() {
    //     try {
    //         let Email = this.state.fields.email;
    //         let Name = this.state.fields.name;
    //         let Title = this.state.fields.title;
    //         let Description = this.state.fields.description;
    //         let response = await fetch('http://sheidakeyhan.com/api/contactUs.php', {
    //             method : 'POST',
    //             headers : {
    //                 'Content-Type' : 'application/json',
    //                 'Access-Control-Allow-Origin': '*',
    //             },
    //             body : JSON.stringify({
    //                 email : Email,
    //                 title : Title,
    //                 name : Name,
    //                 description : Description
    //             })
    //         });
    //         let json = await response.json();
    //
    //         console.log("gdfg",json)
    //         console.log("gdfg",Email)
    //
    //     } catch(error) {
    //         console.log(error)
    //     }
    // }



    handleSubmit(event) {
        event.preventDefault();
        this.handleValidation((valid) => {
            if(valid) this.handleRequest()
        })
    }


    render() {
        let settings = {
            dots: true,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            pauseOnHover: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        let settings2 = {
            dots: true,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2100,
            pauseOnHover: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        const {email , name, title , description} = this.state.fields;
        const { errors } = this.state;
        return (
            <div>
                <div className="background"> </div>
                <Header/>

                <div className="container">
                    <div className="About">
                        <div className="col-md-5">
                            <h1 className="titlePage">Sheida Keyhan haghighi</h1>
                            <p className="textAbout">Sheida Keyhan haghighi is an Iranian painter who was born in Tehran
                                in 1983. At the age of 16 and while studying graphic design at
                                Azadegan Art School, she started painting at  the Ara
                                Painting Studio under the guidance of Master Iman Maleki.

                                In 2006 Sheida graduated in graphic design from the
                                Science and Culture University . She also got her bachelor's degree
                                 in painting in 2015.

                                She has participated in several exhibitions including The Group
                                Exhibition at Kara Studio (Sa'adabad Palace, 2003) and the Group
                                Exhibition at ARA studio (Barg Gallery, 2012).

                                In 2011 she was nominated as a finalist in the Figurative category in
                                the ARC International competition and in 2012 she earned second place in
                                the Landscape category in the ARC competition in USA.</p>
                        </div>
                        <div className="col-md-1 aboutY">
                            <h2 className="heading">About</h2>
                        </div>
                        <div className="col-md-6">
                            <img className="sheidaKeyhan" src={Keyhan} alt="SheidaKeyhan" />
                        </div>
                    </div>
                </div>


                <div className="container">
                    <h2 className="GalleryImage">Gallery</h2>
                    {/*<p className="photoGallery">Photo</p>*/}

                    <Slider {...settings}>
                           <Link className="nav-link" to="/gallery">
                            <div className="colorTag">
                            <div className="sliderDesign">
                                <img alt="portfolio1" src={portfolio1} />
                                <h3> Light. 2012  </h3>
                                <p> Oil On Canvas </p>
                                <p style={{direction: 'ltr'}}> 90 x 70 cm</p>
                            </div>
                           </div>
                        </Link>
                           <Link className="nav-link" to="/gallery">
                            <div className="colorTag">
                            <div className="sliderDesign">
                                <img alt="portfolio1" src={portfolio2} />
                                <h3>Dream Of Night. 2010 </h3>
                                <p>Oil On Canvas</p>
                                <p  style={{direction: 'ltr'}}>60 x 40 cm</p>
                            </div>
                           </div>
                        </Link>
                           <Link className="nav-link" to="/gallery">
                            <div className="colorTag">
                            <div className="sliderDesign">
                                <img alt="portfolio1" src={portfolio3} />
                                <h3> A Curtain of Rain. 2010  </h3>
                                <p> Oil On Canvas</p>
                                <p style={{direction: 'ltr'}}> 80 x 60 cm</p>
                            </div>
                           </div>
                        </Link>
                        <Link className="nav-link" to="/gallery">
                            <div className="colorTag">
                            <div className="sliderDesign">
                                <img alt="portfolio1" src={portfolio4} />
                                <h3> A Date. 2010  </h3>
                                <p > Oil On Canvas</p>
                                <p style={{direction: 'ltr'}}> 110 x 70 cm</p>
                            </div>
                            </div>
                        </Link>

                    </Slider>

                    <Slider {...settings2}>
                        <Link className="nav-link" to="/gallery">
                            <div className="colorTag">
                            <div className="sliderDesign2">
                                <img alt="portfolio1" src={portfolio6} />
                            </div>
                            </div>
                        </Link>

                        <Link className="nav-link" to="/gallery">
                            <div className="colorTag">
                                <div className="sliderDesign2">
                                    <img alt="portfolio1" src={portfolio7} />
                                </div>
                            </div>
                        </Link>

                        <Link className="nav-link" to="/gallery">
                            <div className="colorTag">
                                <div className="sliderDesign2">
                                    <img alt="portfolio1" src={portfolio8} />
                                </div>
                            </div>
                        </Link>

                    </Slider>


                    <Link className="nav-link" to="/gallery">

                        <button  className="btn btnPersonal">
                            Gallery Page
                        </button>

                    </Link>



                </div>

                <div className="container">
                    <h2 className="GalleryImage">Contact Us</h2>


                    <div className="col-md-5">
                        <p className="contactUs">Tell us about your project idea or just say hello</p>
                        <p className="contactUsPage">Address : Tehran</p>
                        <p className="contactUsPage">Phone No : 09125330709</p>
                        <p className="contactUsPage"></p>
                        <p className="contactUsPage">E-mail : sheida_key@yahoo.com</p>
                        <div className="socialsIcon">
                            <a   rel="noopener noreferrer" href="mailto:sheida_key@yahoo.com">
                                <i className="fa fa-yahoo"> </i>
                            </a>
                            <a   rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/sheidakeyhan/">
                                <i className="fa fa-instagram"> </i>
                            </a>
                            <a   rel="noopener noreferrer" target="_blank" href="/">
                                <i className="fa fa-paper-plane"> </i>
                            </a>
                            <a   rel="noopener noreferrer" target="_blank" href="http://facebook.com/sheidakeyhanhagighi">
                                <i className="fa fa-facebook"> </i>
                            </a>
                        </div>
                    </div>
                    <div className="col-md-1 aboutY">
                        <h2 className="headingContact">Contact</h2>
                    </div>

                    <div className="col-md-6">
                        <form onSubmit={this.handleSubmit.bind(this)} className="col-lg-12" style={{ marginTop : 20}}>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label >Name:</label>
                                <input
                                    className={["form-control" , errors["email"] ? 'is-invalid' : ''].join(' ')}
                                    value={name}
                                    onChange={this.handleChange.bind(this)}
                                    type="text"
                                    name="name"
                                />
                                <span className="invalid-feedback rtl" style={{ display : errors["email"] ? 'block' : 'none'}}>{errors["email"]}</span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label>E-mail:</label>
                                <input
                                    value={email}
                                    onChange={this.handleChange.bind(this)}
                                    type="email"
                                    name="email"
                                    className={["form-control" , errors["email"] ? 'is-invalid' : ''].join(' ')}
                                />
                                <span className="invalid-feedback rtl" style={{ display : errors["email"] ? 'block' : 'none'}}>{errors["email"]}</span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label >Title:</label>
                                <input
                                    value={title}
                                    onChange={this.handleChange.bind(this)}
                                    type="text"
                                    name="title"
                                    className={["form-control" , errors["email"] ? 'is-invalid' : ''].join(' ')}
                                />
                                <span className="invalid-feedback rtl" style={{ display : errors["email"] ? 'block' : 'none'}}>{errors["email"]}</span>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="margin-top-20">Description:</label>
                                <textarea
                                    type="text"
                                    className={["form-control" , errors["email"] ? 'is-invalid' : ''].join(' ')}
                                    value={description}
                                    name="description"
                                    onChange={this.handleChange.bind(this)}
                                > </textarea>
                                <span className="invalid-feedback rtl" style={{ display : errors["email"] ? 'block' : 'none'}}>{errors["email"]}</span>
                            </div>
                        </div>
                            {this.state.SuccessForm ?
                                <p className="SuccessForm">Successfully send</p>
                                :
                               <p> </p>
                            }



                        <button type="submit" className="btn btn-Design">Send</button>
                    </form>
                    </div>

                </div>
            </div>
        );
    }
}

export default Home;
