import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import './styles/App.css';
import {
    BrowserRouter as Router,
    Route,
    Switch,
} from "react-router-dom";


import Home from "./components/pages/Home";
import Gallery from "./components/pages/Gallery";
import GalleryFa from "./components/pages/GalleryFa";
import HomeFa from "./components/pages/HomeFa";
import NoMatch from "./components/pages/NoMatch";
import Footer from "./components/sections/Footer";

class App extends Component {
  render() {
    return (
      <div className="App">
            <div className="no-padding">
              <div>
                <Router>
                    <Switch>
                      <Route path="/" exact component={Home} />
                      <Route  path="/gallery" component={Gallery} />
                      <Route  path="/fa" component={HomeFa} />
                      <Route  path="/galleryFa" component={GalleryFa} />
                        <Route component={NoMatch}/>
                    </Switch>
                </Router>
              </div>
            </div>
          <Footer/>
      </div>
    );
  }
}

export default App;
