import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <footer className="footerColor">
                    <p className="FooterText">Sheida Keyhan Haghighi</p>
            </footer>
        );
    }
}

export default Footer;
