import React, { Component } from 'react';
import NavItem from "../NavItem";
import Logo from '../../images/logo.png'

class Header extends Component {
    render() {
        return (
            <nav className="navbar">
                <ul className="nav navbar-nav">
                    <NavItem activeOnlyWhenExact={true} activeClassName="selected" to="/">Home</NavItem>
                    <NavItem to="/gallery">Gallery</NavItem>
                    <NavItem to="/fa"> Fa </NavItem>
                    <NavItem to="/"> En </NavItem>
                </ul>
                <a rel="noopener noreferrer" target="_blank" href="/">
                   <img className="logoNav" src={Logo} alt="logo" />
                </a>
            </nav>
        );
    }
}

export default Header;
